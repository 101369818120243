<template>
  <div class="service">
    <div class="h4">
      软件服务协议
    </div>
    <div class="protect">
      <div class="wel">
        感谢您使用我们的上海蓝十字脑科医院互联网医院客户端（以下简称：“服务平台”）。请您务必审慎阅读、充分理解本协议各条款内容，特别是免除或者限制责任的条款。
      </div>
      <div class="wel">
        除非您已阅读并接受本协议所有条款，否则您无权下载、安装或使用本软件及相关服务。您下载、安装、使用、登录等行为视为您已阅读并同意协议的约束。
      </div> 
      <div class="h5">
        一、 服务条款
      </div>
      <div class="wel">
        1、您同意所有注册协议条款并完成注册程序，才能成为“服务平台”的正式用户。您确认：本协议条款是您与本“服务平台”技术提供方和服务运营方“上海蓝十字脑科医院”（以下简称“运营方”）之间，关于您下载、安装、使用、登录及处理双方权利义务的契约，始终有效，但法律另有强制性规定或双方另有特别约定的，依其规定。
      </div>
      <div class="wel">
        2、您同意本协议的，即视为您确认自己具有享受本客户端服务等相应的权利能力和行为能力，能够独立承担法律责任。
      </div>
      <div class="h5">
        二、 隐私声明
      </div>
      <div class="wel">
        1、适用范围
      </div>
      <div class="wel">
        您在注册“服务平台”客户端时，可能根据网站或客户端要求，需要提供的个人信息，若国家法律法规或政策有特殊规定的，您需要填写真实的身份信息，若您填写的信息不完整，则无法使用本服务或在使用过程中受到限制。若您是18周岁以下未成年人，在使用本服务前，应事先取得您家长或法定监护人的书面同意。
      </div>
      <div class="wel">
        2、信息使用
      </div>
      <div class="wel">
        为服务您的目的，“服务平台”可能通过使用您的个人信息，向您提供服务，包括但不限于向您发出活动和服务信息等。
      </div>
      <div class="wel">
        “运营方”确保非经法律程序不会泄露您的个人信息（如身份证号、电话、住址等）
      </div>
      <div class="wel">
        您在服务平台上与医生一起产生的全部内容，将视为您、医生、医疗机构、服务平台四方所共有。
      </div>
      <div class="wel">
        您了解并同意，一经注册成为平台的用户，即视为您同意平台向您注册时填写的手机号码发送服务信息。您如果不同意接收相关信息，您可以通过相应的退订功能或相关提示进行退订，平台将停止发送信息。
      </div>
      <div class="wel">
        3、用户须做到
      </div>
      <div class="wel">
        您在使用本服务时不得利用本服务从事以下行为，包括但不限于：
      </div>
      <div class="wel">
        （1）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容；
      </div>
      <div class="wel">
        （2）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
      </div>
      <div class="wel">
        （3） 虛构事实、隐瞒真相以误导、欺骗他人；
      </div>
      <div class="wel">
        （4）发表、传送、传播广告信息及垃圾信息;
      </div>
      <div class="wel">
        （5）从事其他违反法律法规、政策及公序良俗、社会公德等的行为。
      </div>
      <div class="wel">
        注册成功后，必须保护好自己的帐号和密码，因您本人泄露而造成的任何损失由您本人负责，造成服务平台损失的，服务平台有权向您追偿。
      </div>
      <div class="wel">
        不得盗用他人帐号，由此行为造成的任何后果均由您自行承担。
      </div>
      <div class="wel">
        4、用户禁止行为
      </div>
      <div class="wel">
        除非法律允许或技术支持方书面许可，您不得从事下列行为：
      </div>
      <div class="wel">
        （1）删除本软件及其副本上关于著作权的信息；
      </div>
      <div class="wel">
        （2）对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；
      </div>
      <div class="wel">
        （3）对技术支持方拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
      </div>
      <div class="wel">
        （4）对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经授权的第三方工具/服务接入本软件和相关系统；
      </div>
      <div class="wel">
        （5）通过修改或伪造软件运行中的指令、数据，增加、删减变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
      </div>
      <div class="wel">
        （6）通过非技术支持方开发、授权的第三方软件、插件、外挂、系统，登录或使用软件及服务，或制作、发布、传播上述工具;
      </div>
      <div class="wel">
        （7）自行、授权他人或利用第三方软件对本软件及其组件、模块、数据等进行干扰；
      </div>
      <div class="wel">
        5、您同意，服务平台、运营方拥有对本政策单方不定时修改的权利。本政策修改，将不会单独征求您的意见并无需取得您的事先同意，所有的修改均以服务平台最新发布的政策版本为准，如您不同意本政策修改的，则您应立即注销您的账号并不再使用；如您继续使用服务平台的任一功能的，则视为您接受本政策的修改。
      </div>
      <div class="wel">
        服务平台有权随时对您的注册数据及咨询的行为进行查阅，发现注册数据或咨询行为中存在任何问题或怀疑，均有权向您发出询问及要求改正的通知或者直接作出删除等处理。
      </div>
      <div class="h5">
        三、 知识产权声明
      </div>
      <div class="wel">
        运营方系本服务平台软件的知识产权权利人。本软件的著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，运营方依法享有上述相关知识产权，但相关权利人依照法律规定应享有的权利除外。
      </div>
      <div class="wel">
        未经权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。
      </div>
      <div class="h5">
        四、 客户端服务
      </div>
      <div class="wel">
        本客户端服务是按照现有技术和条件所能达到的现状提供的。运营方会尽最大努力为您提供服务，确保服务的连贯性和安全性；但服务平台、运营方均不对不能随时预见和防范的法律、技术以及其他风险引起的后果承担责任，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为、电力供应不足等原因可能导致的服务中断、数据丟失以及其他的损失和风险。
      </div>
      <div class="h5">
        五、 服务内容
      </div>
      <div class="wel">
        运营方有义务在现有技术上维护平台服务的正常进行，并努力提技术及改进技术，使软件服务更好进行，增进用户体验。
      </div>
      <div class="wel">
        您对服务内容不满意，可以向服务平台提出投诉，运营方有义务依据情況协调沟通，维护您和医生关系和谐。
      </div>
      <div class="h5">
        六、 免责条款
      </div>
      <div class="wel">
        服务平台客户端所有信息仅供参考，不做个别诊断、用药和使用的依据。服务平台致力于尽量提供正确，完整的健康资讯，但不保证信息的绝对正确性和完整性，且不对因信息的不正确或遗漏导致的任何损失或损害承担责任。服务平台所展现的任何医药资讯，仅供参考。如自行使用服务平台所展现资料发生偏差，服务平台不承担任何法律责任。
      </div>
      <div class="wel">
        服务平台致力于打造新型移动智慧医疗互联平台，平台运营方对服务平台客户端上得到的任何相关的诊疗、电子处方、咨询、护理上门等等不担保治疗效果。对此产生的医疗后果，不承担任何法律责任。
      </div>
      <div class="h5">
        七、 违约处理
      </div>
      <div class="wel">
        1、如果发现或收到他人举报您有违反本协议约定的，我们有权不经通知随时对相关内容进行州除、屏蔽，并采取包括但不限于暂停、终止您使用服务平台，暂停、终止您使用部分或全部本服务，追究法律责任等措施
      </div>
      <div class="wel">
        2、对第三方损害的处理
      </div>
      <div class="wel">
        您违反约定，导致任何第三方损害的，您应当独立承担责任；如本服务平台或运营方因此遭受损失的，您也应当一并赔偿。
      </div>
      <div class="h5">
        八、法律管辖和适用
      </div>
      <div class="wel">
        本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向唯一有管辖权的上海市闵行区人民法院提起诉讼。
      </div>
      <div class="h5">
        九、 其他
      </div>
      <div class="wel">
        本协议最终解释权归属服务平台和运营方，您注册使用本客户端即视为您完全接受本协议，若后续有修改将在网站及客户端更新，不再另行通知，请您再次确认已知悉并完全理解本协议的全部内容。
      </div>
      <div class="bold bottom">
        特别声明：您确认，您同意或视为同意本协议的约束，均是您在完全自由情况下的真实意思表示，不存在任何欺诈、胁迫或重大误解。
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
      return{
      }
    },
    mounted(){
    },
    methods:{
    }
}
</script>
<style scoped lang='less'>
.service {
  padding: 40px;
  color: #000;
  .h4 {
    font-size: 40px;
    font-weight: bold;
    line-height: 46px;
    margin-bottom: 40px;
    text-align: center;
  }
  .protect {
    font-size: 32px;
    text-align: justify;
    .h5 {
      font-weight: bold;
      font-size: 36px;
      padding-bottom: 20px;
      padding-top: 20px;
      line-height: 60px !important;
    }
    .wel {
      font-size: 32px;
      line-height: 60px;
      color: #333;
      text-indent: 60px;
      .strong {
        font-weight: bold;
      }
    }
    .bold {
      font-weight: bold;
    }
    .line {
      height: 20px;
      background: #FFFFFF;
      width: 100%;
    }
    .bottom {
      margin-top: 10px;
    }
  }
}
</style>
